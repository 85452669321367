// var stickyHeader = false;
// var transformHomepageDonationAmountImages = false;
// var flyoutNav = false;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
// var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// Open Leeds Teaching Hospitals link in new tab
$('#menuAdmin li[class*="leedsth"] a').attr('target', '_blank');

if ($('body').hasClass('homepage')) {
  // Move quick giving and stats
  $('.homefeaturecategory-homeboximpactstats').insertBefore('.homeFeatures');
  $('.homefeaturecategory-homeboxquickgiving').insertAfter('.homefeaturecategory-homeboximpactstats');

  $('.homeFeatures .homeFeature').wrapAll('<div class="containedFeatures"></div>');

  // // Story of the month - They decided to scrap this https://www.bugherd.com/projects/219711/tasks/3
  // $('.homeBox1').wrap('<div class="story"></div>');
  // $('<h2 class="storyTitle">Story of the Month</h2>').prependTo('.story');
  // var styleSheet = document.createElement("style");
  // styleSheet.type = "text/css";
  // document.head.appendChild(styleSheet);

  // function setStoryFrameSize() {
  //   var storyImgHeight = $('.story img').css('height');
  //   var storyImgWidth = $('.story img').css('width');
  //   var storyImgFrameCss = `.homeFeatures .story .home-feature-image-link:after{height:${storyImgHeight}; width:${storyImgWidth};};`
  //   styleSheet.innerText = storyImgFrameCss;
  // }
  // setStoryFrameSize();
  // window.onresize = function () {
  //   setStoryFrameSize();
  // }

  // Animate home intro elements while scrolling
  document.onscroll = function () {
    var introWrapper = document.querySelector('.homeIntroWrapper');
    if (window.innerWidth > 768) {
      var rect = introWrapper.getBoundingClientRect().top / 50;
      $('.homeIntroWrapper').css('background-position', (5 - rect) + '% 200px, ' + (rect + 90) + '% -110px');
    } else {
      var rect = introWrapper.getBoundingClientRect().top / 20;
      $('.homeIntroWrapper').css('background-position', (5 - rect) + '% 200px, ' + (rect + 80) + '% -80px');
    }
  }
}

// Our team - square images
if ($('body').hasClass('pages-category-our-team')) {
  $('.listed-post-image-link img').each(function () {
    var oldsrc = $(this).attr('src');
    var substring = oldsrc.substring(0, oldsrc.indexOf("width"));
    var newsrc = substring + "width=500&height=500";
    $(this).attr('src', newsrc);
  })
}

// Better-sized donate thumbnails
// if ($('body').hasClass('donate')) {
//   $('.donationAmountThumbnail').each(function () {
//     var oldsrc = $(this).attr('src');
//     var substring = oldsrc.substring(0, oldsrc.indexOf("width"));
//     var newsrc = substring + "width=420&height=420";
//     $(this).attr('src', newsrc);
//   })
// }

$(".eventDetails").insertBefore(".postFooterWrapper");

$('document').ready(function () {
  if ($('body').hasClass('appeal-friend')) {
    $(".quickGivingPanel").appendTo(".headerText");
    $(".headerText .quickGivingPanel .donationAmountOther .donationAmountFigure").html("Other £");
    $(".donationAmountOther").on('click', function(){
      window.location = "https://payments.accessacloud.com/Leeds-Cares/Donate";    
    });
    $(".headerText .quickGivingPanel .donateBtn").html("Donate monthly");
    $(".headerText .quickGivingPanel .selectDonationAmount").append('<a class="button singleDonateBtn" href="/donate/donate" role="button">One off donation</a>');
  }
})